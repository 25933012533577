.property-grid {
    
    @include tablet {
        display: grid;
        grid-gap: 1em;
        //grid-template-columns: 1fr 1fr;
        grid-template-columns: 49% 49%;
        grid-column-gap: 2em;

        .property-grid__slider {
            order: 1;
            grid-column-start: 1;
            grid-row-start: 1;
            grid-row-end: 3;
            
        }
        .property-grid__intro {
            order: 2;
            grid-column-start: 2;
            grid-row-start: 1;
        }

        .property-grid__desc {
            order: 3;
            grid-column-start: 2;
            grid-row-start: 2;
            grid-row-end: span 4;
        }


        .property-grid__features {
            order: 4;
            grid-row-start: 3;
            grid-column-start: 1;
        }


        .property-grid__location {
            order: 7;
            grid-column-start: 1;
        }
    }
}