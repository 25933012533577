@charset "utf-8";

@import "background";
@import "flex";
@import "spacing";
@import "size";
@import "text";







.is-borderless {
    border: 0;
}




.has-overlay {
    position: relative;
    display: block;
}



.has-bg-overlay {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 2;
    }

    &.is-light {
        &:before {
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    &.is-grey {
        &:before {
            background-color: rgba(154, 154, 154, 0.5);
        }
    }

    &.is-blue {
        &:before {
            background-color: rgba(13, 47, 111, 0.5);
        }
    }
}


.is-position-static {
    position: static;
}

.is-underlined {
    text-decoration: underline;
}











.iframe {
    &.is-16by9, &.is-youtube {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        padding-top: 25px;
        height: 0;
    }
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}



.is-circle {
    border-radius: 50%;
    &.is-32 {
        width: 32px;
        height: 32px;
    }
    &.is-42 {
        width: 42px;
        height: 42px;
    }
}



.is-foreground {
    z-index: 9;
}


.is-greyscale {
    filter: grayscale(100%);
    &.is-hoverable {
        transition: filter 0.3s;
        &:hover {
            filter: grayscale(0%);
        }
    }
}





@include mobile {
    .is-scroll-x-mobile {
        overflow-x: scroll;
    }

    .is-w-200-mobile {
        width: 200px;
    }
}



ul {
    &.is-wrapped {    
        column-count: 2;
        column-gap: 30px;
    }

    &.has-discs {
        list-style-type: disc;
    }
}
/*
@include tablet {
    .is-wrapped {
        column-count: 2;
        column-gap: 30px;
    }
}
*/
ul.is-wrapped {
    li {
        margin-bottom: 0.8rem;
    }
}

.has-links-not-decorated {
    a {
        text-decoration: none !important;
    }
}

/*html {
    scroll-behavior: smooth;
}*/


.section.is-small {
    padding: 2rem 1.5rem;
}

