.has-text-white {
    strong {
        color: $white;
    }

    a {
        &:not(.navbar-item):hover {
            color: $white;
        }
    }
}

@include mobile {
    .has-text-white-mobile {
        color: $white !important;
    }
}


.has-unstyled-lists {
    ul, ol {
        margin-left: 0;
    }
    li {
        list-style: none;
    }
}


.content {
    &.is-small-medium {
        font-size: $size-small-medium;
    }

    &.has-inline-p {
        p {
            display: inline;
        }
    }

    &.has-hidden-br br {
        display: none;
    }

    table {
        @extend .table;
        //@extend .table.is-bordered;
        @extend .table.is-hoverable;
        @extend .table.is-striped;

        th, td {
            border: 1px solid transparent;
            padding: 0.75em;
        }

        th {
            font-size: $size-5;
        }

        /*tr:last-child td, tbody tr:last-child th, tbody tr:last-child th {
            border-bottom-width: 1px !important;
        }

        thead th {
            border-width: 1px;
        }*/
    }
}

.has-medium-content {
    .title {
        font-size: $size-3;
    }

    .content {
        font-size: $size-5;
    }
}