
.hero {
    &.has-image {
        position: relative;
        overflow: hidden;
        height: 80vh;
        height: calc(100vh - 140px);
        
        .hero-image img {
            height: 80vh;
            height: calc(100vh - 140px);
            object-fit: cover;
            object-position: center;
        }

        &.is-large {
            height: 80vh;
            height: calc(100vh - 88px);
            .hero-image img {
                height: 80vh;
                height: calc(100vh - 88px);
            }

            &.has-bar {
                height: calc(95vh - 88px);
                .hero-image img {
                    height: calc(95vh - 88px);
                }
            }
        }

        &.is-fullheight {
            height: 100vh;
            height: calc(100vh - 104px);
            min-height: calc(100vh - 104px);
            img {
                height: 100vh !important;
                height: calc(100vh - 104px) !important;
            }
        }
        
        &.is-medium, &.is-600 {
            height: 600px;
            .hero-image img {
                height: 600px;
            }
        }
         
        &.is-250 {
            height: 250px;
            .hero-image img {
                height: 250px;
            }
        }



        .hero-body {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 3;
        }
    }

    .hero-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;

        &.is-left {
            left: 0;
        }

        &.is-right {
            right: 0;
        }
    }
}


header.is-overlayed, header.is-overlayed-disabled {
    +.hero {
        &.has-image {
            height: 100vh;
            //height: calc(100vh - 25px);
            img {
                height: 100vh !important;
                //height: calc(100vh - 25px) !important;
            }
        }
    }
}

/*header + .hero, .hero.is-fullheight {
    &.has-image {
        height: 100vh;
        height: calc(100vh - 104px) !important;
        img {
            height: 100vh !important;
            height: calc(100vh - 104px) !important;
        }
    }
}*/