.title, .subtitle {
    @each $size in $sizes {
        $i: index($sizes, $size);

        @media screen and (max-width: 440px) {
            &.is-#{$i}-small-mobile {
                font-size: $size;
            }
        }

        @include mobile {
            &.is-#{$i}-mobile {
                font-size: $size;
            }
        }

        @include tablet {
            &.is-#{$i}-tablet {
                font-size: $size;
            }
        }

        @include tablet-only {
            &.is-#{$i}-tablet-only {
                font-size: $size;
            }
        }

        @include desktop {
            &.is-#{$i}-desktop {
                font-size: $size;
            }
        }
        
        @include widescreen {
            &.is-#{$i}-widescreen {
                font-size: $size;
            }
        }
    }


    &.is-section-title {
        position: relative;
        color: #878787;
        padding-bottom: 30px;
        margin-bottom: 45px;

        &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 6px;
            width: 200px;
            background-color: #c21f41
        }

        &.is-underlined-left {
            &:before {
                left: 0;
                transform: none;
            }
        }
    }


    &.is-extra-large {
        font-size: 3rem;
    }
    @include tablet {
        &.is-extra-large-tablet {
            font-size: 3rem;
        }   
    }
    @include desktop {
        &.is-extra-large-desktop {
            font-size: 3rem;
        }   
    }


    &.is-line-spaced {
        line-height: 1.35;
    }
}


.has-background-danger {
    .is-section-title:before {
        background-color: $black-bis;
    }
}

.has-text-white {
    .title, .subtitle {
        color: $white;
    }
}