.button {
    &.is-dark {
        &.is-semi-transparent {
            background-color: rgba(54, 54, 54, 0.8);
        }
    }

    &.is-primary {
        &.is-semi-transparent {
            //background-color: rgba(217, 17, 141, 0.8);
            background-color: rgba(54, 54, 54, 0.8);
        }
    }

    @include mobile {
        &.is-small-mobile {
            font-size: $size-small;
        }
    }

    @media screen and (max-width: 400px) {
        &.is-small-mobile-narrow {
            font-size: $size-small;
        }
    }

    @include tablet {
        &.is-medium-tablet {
            font-size: $size-medium;
        }
    }
}