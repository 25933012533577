.is-margin-centered {
    margin-left: auto !important;
    margin-right: auto !important;
}

.is-paddingless-h {
    padding-left: 0;
    padding-right: 0;
}
.is-paddingless-v {
    padding-top: 0;
    padding-bottom: 0;
}
.is-paddingless-top {
    padding-top: 0;
}

.is-padded {
    padding: 1rem;
}
.is-padded-h {
    padding-left: 1rem;
    padding-right: 1rem;
}
.is-padded-v {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
@include tablet {
    &.is-padded-tablet {
        padding: 1rem;
    }
}

.is-padded-small {
    padding: 0.5rem;
}

.is-marginless-bottom {
    margin-bottom: 0 !important;
}

.is-margin-bottom-10 {
    margin-bottom: 10px !important;
}