$navbar-padding-horizontal: 0;
$navbar-padding-vertical: 1rem;
$navbar-dropdown-radius: 0;
$navbar-dropdown-boxed-radius: 0;


$radius: 0;
//$primary: #363636;
$primary: #d9118d;
$primary-light: #fd94d5;
$dark: #4c4c4c;

$footer-padding: 3rem 1.5rem;

$custom-colors: ("facebook": #4267b2,"twitter":rgb(29,161,242),"whatsapp":#00bfa5);

$size-normal: 0.9rem;

$size-1: 2.5rem;
$size-2: 2rem;
$size-3: 1.5rem;
$size-4: 1.25rem;
$size-5: 1.15rem;
$size-6: 0.95rem;
$size-6-5: 0.85rem;
$size-7: 0.75rem;

$size-small-medium: $size-6-5;


$section-padding: 2rem 1.5rem;

$content-table-cell-border:	0;	
$content-table-cell-border-width: 0;
$content-table-cell-padding: 0.75em 0.75em;

$content-table-cell-heading-color: hsl(0, 0%, 21%);
$content-table-head-cell-border-width: 0;	
$content-table-head-cell-color:	hsl(0, 0%, 21%);

$content-table-foot-cell-border-width: 0;
$content-table-foot-cell-color:	hsl(0, 0%, 21%);


//$family-sans-serif: "Open Sans", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-sans-serif: "Lato", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;