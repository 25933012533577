
.modal-content {
    @include mobile {
        &.is-fullheight-mobile {
            max-height: 100vh;
        }
    }

    &.has-gallery {
        width: 90%;

        @include tablet {
            width: 70%;
            max-width: 1500px;
        }

        .swiper-zoom-container {
            height: calc(100vh - 160px);
        }
        @include tablet {
            .swiper-zoom-container {
                height: calc(100vh - 40px);
            }
        }
    }
}


.modal-close.is-dark {
    &:before, &:after {
        @extend .has-background-dark;
    }
}