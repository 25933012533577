.tag {
    &.tag--feature {
        white-space: normal;
        width:100%;
        justify-content:left;

        @include mobile {
            min-height: 2em;
            height: auto;
        }
    }

}