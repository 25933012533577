.navbar {
    .dropdown-menu {
        position: static;
        @include desktop {
            &.is-fullwidth {
                position: absolute;
                left: 0;
                width: 100%;
                top: 80px;
            }
        }
    }
    
    @media screen and (max-width: $desktop - 1px) {
        .dropdown:not(.is-active).is-hoverable:hover .dropdown-menu {
            display: none;
        }
    }

    .dropdown.is-active .dropdown-menu {
        display: block;   
    }

    

    @include mobile {
        .section {
            padding: 10px 0;
        }
    }

    @include tablet-only {
        .section {
            padding: 10px 0;
        }
    }
}


.dropdown__submenu {
    margin-bottom: 6px;

    @include desktop {
        margin-bottom:12px;
    }
}

@media screen and (max-width: $desktop - 1px) {
    .dropdown__submenu-title.title {
        font-size: 1rem;
        margin-bottom: 10px;
        &.is-spaced {
            margin-bottom: 10px;
        }
    }

    .navbar-item.has-separator-mobile {
        border-bottom: 1px solid #efefef;
    }
}