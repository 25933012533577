.footer {
    padding: $footer-padding;
    a {
        color: #f1f1f1;
    }
}

.footer-logo {
    margin-bottom: 4px;
    svg {
        width: 150px;
        height: 47px;
    }
}