.is-simple-fullwidth {
    width: 100%;
}
.is-simple-fullheight {
    height: 100%;
}

@include mobile {
    .is-simple-fullwidth-mobile {
        width: 100%;
    }
}


.is-min-height-400 {
    height: 400px;
}



$widths: 100 150 200 250 300 350 500 600 700 800 950 1150;

@each $size in $widths {
    .is-max-w-#{$size} {
        max-width: #{$size}px;
    }

    .is-min-w-#{$size} {
        min-width: #{$size}px;
    }
}