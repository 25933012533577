.select select, .input, input.button {
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.select:not(.is-multiple):not(.is-loading)::after {
    border-color: $grey;
    right: 1.125em;
    z-index: 4;
}

label.has-radio {
    input[type="radio"] {
        width: 0;
        height: 0;
        opacity: 0;
    }
}

.label.is-small {
    font-size: $size-normal;
}

.select select, .input, .textarea {
    &.is-transparent {
        background: transparent;
        &:not(:focus) {
            box-shadow: none;
        }
    }

    &.is-bordered-bottom {
        border: 0;
        border-bottom: 2px solid $grey;
    }
}


.input, .textarea {
    &::placeholder {
        color: $grey-light;
    }
}









.nouislider-wrapper {
    height: 2.25em;
}