.swiper-nav-button {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.4s, background-color 0.4s;
    max-width: 40px;

    &.is-large {
        max-width: 50px;
    }

    &:not(.has-no-background):not(.has-no-background-tablet):hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    &.has-no-background {
        background: none;
    }

    @include tablet {
        &.has-no-background-tablet {
            background: none;
        }
    }

    &.is-outer {
        @include desktop {
            background: none;
            svg path {
                fill: #000;
            }
        }
    }
}

.swiper-container:hover .swiper-nav-button,
.swiper-nav-button__shown {
    opacity: 1;
}

.swiper-nav-button__prev {
    left: 0;

    &.is-outer {
        left: -20px;

        @include tablet {
            left: -20px;
        }

        @include desktop {
            left: -50px;
        }
    }
}
.swiper-nav-button__next {
    right: 0;

    &.is-outer {
        right: -20px;

        @include tablet {
            right: -20px;
        }

        @include desktop {
            right: -50px;
        }
    }
}


.swiper-lazy[data-src] {
    opacity: 0;
    transition: opacity .6s;
}

.swiper-lazy-loaded {
    opacity: 1;
}

.swiper-slide {
    .swiper-lazy-preloader {
        opacity: 0;
    }

    &.swiper-slide-active {
        .swiper-lazy-preloader {
            opacity: 1;
        }
    }

    &.is-equal-height {
        height: auto;
    }





    &.swiper-fade-on-slide {
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
        &.swiper-slide-active, &.swiper-slide-duplicate-active {
            opacity: 1;
        }
    }
}







.is-multiple-per-view.is-mobile {
    .swiper-slide {
      width: 70% !important;
      .hero {
        box-sizing: border-box;
      }
    }
}

.swiper-pagination-bullet {
    background-color: $white;
    opacity: .3;
}

.swiper-pagination-bullet-active {
    background-color: $primary !important;
    opacity: 1;
}



.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    /*position: relative;
    margin-top: 25px;*/
    bottom: 40px;
}

.swiper-container-horizontal>.swiper-pagination-gsir.swiper-pagination-bullets {
    @include mobile {
        bottom: 70px;
        padding: 0;
        text-align: center;
    }

    &:focus, &:active {
        outline: none;
    }
    
    z-index: 2;
    bottom: 120px;
    padding-right: 60px;
    text-align: right;
    font-weight: bold;

    .swiper-pagination-bullet {
        background: none;
        opacity: 1;
        margin: 0 20px;
        position: relative;
        height: auto;
        width: auto;

        &:before {
            content: "";
            display: block;
            bottom: -4px;
            height: 2px;
            left: 0;
            right: 0;
            position: absolute;
            background: #fff;
            opacity: 0;
            transition: .3s;
        }
    } 
    
    .swiper-pagination-bullet-active {
        background: none !important;

        &:before {
            opacity: 1;
        }
    }
}


.swiper-container {
    &.is-swiper-centered {
        .swiper-slide {
            background-color: #252525;
        } 
    }

    .columns, .card {
        box-sizing: border-box;
    }


    &.has-opaque-sides {
        &:before, &:after {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 50px;
            background-color: inherit;
            z-index: 9; 
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        @include tablet {
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    @include tablet {
        &.has-opaque-sides-tablet {
            padding-left: 60px;
            padding-right: 60px;

            &:before, &:after {
                content: '';
                position: absolute;
                top: 0;
                height: 100%;
                width: 50px;
                background-color: inherit;
                z-index: 9; 
            }
    
            &:before {
                left: 0;
            }
    
            &:after {
                right: 0;
            }
        }
    }
}

.swiper-slide {
    &.is-fullheight {
        height: auto;
    }
}




.swiper-wrapper {
    &[data-target-modal="gallery"] {
        cursor: zoom-in;
    }
} 