.is-flex-v-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.is-flex-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.is-flex-justify-center {
    justify-content: center;
}

@include tablet {
    .is-flex-centered-tablet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}