
.overlay {
    position: absolute;
    z-index: 9;
    opacity: 1;
    transition: opacity .3s;

    &.is-centered {
        left: 50%;
        transform: translateX(-50%);
    }

    &.is-centered-v {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &.is-bottom {
        bottom: 10%;
        @include tablet {
            &.is-halfish {
                bottom: 30%;
            }
        }
    }

    &.is-bottom-0 {
        bottom: 0;
    }

    &.is-top {
        top: 10%;
    }

    &.is-left {
        left: 6%;
    }

    @include mobile {
        &.is-top-fullwidth-mobile {
            bottom: auto;
            top: 0;
            left: 0;
            width: 100%;
        }

        &.is-bottom-fullwidth-mobile {
            top: auto;
            bottom: 0;
            left: 0;
            width: 100%;
        }
    }
}

.media-hover-overlay:hover + .overlay {
    opacity: 0;
}
