.columns {
    @include tablet {
        &.is-centered-tablet {
            justify-content: center;
        }

        &.is-multiline-tablet {
            flex-wrap: wrap;
        }
    }

    &.has-margin-small {
        margin-bottom: 6px !important;
    }

    
    &.is-gapless-v {
        margin-top: 0;
        > .column {
            margin-top: 0;
            margin-bottom: 0;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }


    .columns.is-nested {
        margin: 0;
        padding: 0;
        .column {
            margin: 0;
            padding: 0; 
            &.is-nested-hidden {
                display: none;
            }
        }
    }
}


.columns.has-nested .columns > .column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75rem;
    width: initial;
}


.columns-inactive > .column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75rem;
    width: initial;

    .columns-inactive-hidden {
        display: none;
    }

    .columns-inactive-has-background-light {
        @extend .has-background-light;
    }

    .columns-inactive-box {
        @extend .box;
    }
}

.columns:not(.columns-inactive) .columns-active-is-shdowless {
    @extend .is-shadowless;
}

@include widescreen {
    .is-narrow-widescreen {
        width: auto !important;
    }
}