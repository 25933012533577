.has-background-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.is-contain {
        background-size: contain;
    }
}

.has-background-gradient {
    background-image: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%);
    &.is-short {
        background-image: linear-gradient(0deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 50%);
    }
}

.has-background-transparent {
    background: transparent;
}

.has-background-light-semi-transparent {
    background-color: rgba(181, 181, 181, 0.6);
}