.navbar {
    padding: 1rem;
    //background: $grey;
    background: $white;

    -webkit-animation: none;
    animation: none;
    &.is-down {
        @extend .is-fixed-top-desktop;
        @extend .has-background-dark;
        -webkit-animation: move-down .2s forwards;
        animation: move-down .2s forwards;
        transform: translateY(-100px);

        @include desktop {
            .navbar-item {
                & > a {
                    color: $white;
                }
                &::after {
                    border-color: #fff;
                }
            }
        }

        .is-logo {
            width: 150px;
            height: 46.84px;
            transition: color .3s;

            g, path, polygon, rect {
                fill: #ffffff;
                transition: fill .3s;
            }

            &:hover {
                color: $grey;
                g, path, polygon, rect {
                    fill: $grey;
                }
            }

            
        }
    }



    &.is-overlayed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        //background: rgba(46, 46, 46, 0.9);
        //background: rgba(122, 122, 122, 0.9);
        &.is-transparent {
            //background: transparent;

            .logo {
                width: 200px;
                height: 62px;
            }
        }
        @include desktop {
            .navbar-item {
                & > a {
                    //color: $white;
                }
            }
        }

        .logo {
            //color: $white;
        }

        .navbar-burger {
            color: $white;
            background-color: $grey;
        }
    }

    
}

.navbar-item {
    .is-bordered-onhover {
        position: relative;
        padding-bottom: inherit;
        padding-top: inherit;

        &:before {
            content: "";
            display: block;
            position: absolute;
            bottom: -3px;
            height: 2px;
            width: 100%;
            left: 0;
            background: $white;
            opacity: 0;
            transition: .3s;
        }

        &:hover {
            &:before {
                bottom: 0;
                opacity: 1;
            }
        }
    }

    .logo {
        width: 180px;
        height: 56px;
        transition: color .3s;
        &:hover {
            color: initial;
            color: $dark;
        }
    }
}

.navbar-menu {
    @include mobile {
        position: absolute;
        width: 100%;
        top: 90px;
        left: 0;
    }

    @include tablet-only {
        position: absolute;
        width: 100%;
        top: 90px;
        left: 0;
    }
}

.navbar-item.dropdown > a {
    font-size: 15px;
}

.navbar-end > .navbar-item {
    a {
        color: $black;
        @include desktop {
            //color: $white;
        }
    }
}


.navbar-link {
    color: $black;
}




@-webkit-keyframes move-down {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes move-down {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}



#top-offset-observer {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 250px;
    left: 0;
}

body.has-fixed-header {
    padding-top: 88px;
}


.navbar-divider {
    display: block;
}



.navbar-dropdown {
    &.is-special {
        @include desktop {
            width: 20rem;
        }

        .navbar-item {
            white-space: normal;
        }
    }
}

.navbar-link-special  {
    padding: 1rem;
    color: $grey;
    &:hover {
        color: $black !important;
    }
}

.navbar-link-title {
    font-size: 1rem;
}


// for icons
.navbar-item {
    .icon.is-hidden-inactive {
        display: none;
    }

    &.is-active {
        .icon.is-hidden-active {
            display: none;
        }
        .icon.is-hidden-inactive {
            display: inline-flex;
        }
    }

    @include mobile {
        &:not(.is-active) {
            .navbar-dropdown.is-special {
                display: none;
            }
        }
    }

    @include tablet-only {
        &:not(.is-active) {
            .navbar-dropdown.is-special {
                display: none;
            }
        }
    }
}