.noUi-connect {
    background-color: $grey; 
}

.noUi-horizontal {
    height: 8px;
}

.noUi-horizontal .noUi-handle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    box-shadow: none;
    top: -6px;
    background-color: $dark;
    border: none;
    

    &:before, &:after {
        content: initial;
    }
}

