.card {
    &.is-fullheight {
        height: 100%;
    }
}

.card-content {
    &.is-wide {
        padding: 1.5rem .5rem;
    }
}

.card.has-background-image {
    .card-image {
        height: inherit;
    }
}


.card-image-overlay {
    position: absolute;
    background-color: rgba(0,0,0, 0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .4s;

    &.has-background-gradient {
        background-color: transparent;
        @extend .has-background-gradient
    }

    &.is-displayed {
        opacity: 1;
        transition: background .4s;
        &:hover {
            background-color: rgba(0,0,0, 0.2);
        }
    }

    @include mobile {
        &.is-displayed-mobile {
            opacity: 1;
        }
    }
}

.card {
    &:hover .card-image-overlay {
        opacity: 1;
    }
}